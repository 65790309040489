import { createContext, useState, useEffect } from "react";

const VoucherContext = createContext({
  voucher: [],
  addVoucher: (voucherItem) => {},
  removeVoucher: (voucherId) => {},
  removeAllVoucher: () => {},
});

export const VoucherContextProvider = (props) => {
  const initialState = [];
  const [cartVoucher, setCartVoucher] = useState(initialState);

  // // for initial voucher local storage
  // useEffect(() => {
  //   const voucherData = JSON.parse(localStorage.getItem("voucher_cart_item"));
  //   if (voucherData) {
  //     setCartVoucher(voucherData);
  //   }
  // }, []);

  // useEffect(() => {
  //   if (cartVoucher !== initialState) {
  //     localStorage.setItem("voucher_cart_item", JSON.stringify(cartVoucher));
  //   }
  // }, [cartVoucher]);

  // add voucher item into cart handler
  function addVoucherHandler(voucherItem) {
    setCartVoucher((prevVoucher) => [...prevVoucher, voucherItem]);
  }

  //remove voucher item
  function removeVoucherHandler(voucherId) {
    setCartVoucher((prevVoucher) => {
      const updateVoucher = [...prevVoucher];
      updateVoucher.splice(voucherId, 1);
      return updateVoucher;
    });
  }

  //remove voucher all item
  function removeAllVoucherHandler() {
    setCartVoucher((prevVoucher) => {
      const updateVoucher = [];
      return updateVoucher;
    });
  }

  const context = {
    voucher: cartVoucher,
    addVoucher: addVoucherHandler,
    removeVoucher: removeVoucherHandler,
    removeAllVoucher: removeAllVoucherHandler,
  };
  return <VoucherContext.Provider value={context}>{props.children}</VoucherContext.Provider>;
};
export default VoucherContext;
