export const TIKTOK_PIXEL_ID = process.env.NEXT_PUBLIC_TIKTOK_PIXEL_ID;

export const pageview = () => {
  ttq.page();
};

export const event = (name, options = {}) => {
  ttq.track(name, options);
};
export const initiateCheckout = () => {
  ttq.track("InitiateCheckout");
};
