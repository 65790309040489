import { createTheme } from "@mui/material/styles";
import {
  RxDoubleArrowLeft,
  RxDoubleArrowRight,
  RxChevronLeft,
  RxChevronRight,
} from "react-icons/rx";
import { Breakpoint } from "./media-query";

const previosPagination = () => {
  const [isDesktop, isTablet, isMobile] = Breakpoint();
  return !isMobile ? "Previous" : <RxChevronLeft />;
};
const nextPagination = () => {
  const [isDesktop, isTablet, isMobile] = Breakpoint();
  return !isMobile ? "Previous" : <RxChevronRight />;
};
const firstPagination = () => <RxDoubleArrowLeft />;
const lastPagination = () => <RxDoubleArrowRight />;

const MuiTheme = createTheme({
  palette: {
    primary: {
      light: "#757ce8",
      main: "#303030",
      dark: "#002884",
      contrastText: "#fff",
    },
    success: {
      main: "#fff",
    },
  },
  typography: {
    fontFamily: [
      "NeuzeitGrotesk-light",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  components: {
    MuiPaginationItem: {
      defaultProps: {
        components: {
          previous: previosPagination,
          next: nextPagination,
          first: firstPagination,
          last: lastPagination,
        },
      },
    },
  },
});
export default MuiTheme;
