import { createContext, useState, useEffect } from "react";

const CartContext = createContext({
  cart: [],
  totalCart: 0,
  addToBag: (addToCartItem) => {},
  uppdateToBag: (cartIs, updatedQty) => {},
  removeQty: (cartId) => {},
  addQty: (cartId) => {},
  removeFromBag: (cartId) => {},
  removeAllFromBag: () => {},
  updateClickAndCollect: (cartId, store) => {},
});

export const CartContextProvider = (props) => {
  const initialState = [];
  const [userCart, setUserCart] = useState(initialState);

  // for initial cart local storage
  useEffect(() => {
    const cartData = JSON.parse(localStorage.getItem("cart_item"));
    if (cartData) {
      setUserCart(cartData);
    }
  }, []);

  useEffect(() => {
    if (userCart !== initialState) {
      localStorage.setItem("cart_item", JSON.stringify(userCart));
    }
  }, [userCart]);

  // add to bag handler
  function addToBagHandler(addToCartItem) {
    return new Promise((resolve, reject) => {
      const matchingCartItem = userCart.find(
        (cart) =>
          cart.product === addToCartItem.product &&
          isEqual(cart.attributeSelected, addToCartItem.attributeSelected) &&
          isEqual(cart.customText, addToCartItem.customText)
      );

      if (matchingCartItem) {
        setUserCart((prevUserCart) => {
          const updatedCart = prevUserCart.map((cart) => {
            if (cart === matchingCartItem) {
              return { ...cart, qty: cart.qty + addToCartItem.qty };
            }
            return cart;
          });
          resolve(updatedCart); // Resolve with the updated cart
          return updatedCart;
        });
      } else {
        setUserCart((prevUserCart) => {
          const updatedCart = [...prevUserCart, addToCartItem];
          resolve(updatedCart); // Resolve with the updated cart
          return updatedCart;
        });
      }
    });
  }

  // function for match attribute object
  function isEqual(obj1, obj2) {
    if (!obj1 || !obj2) {
      return false;
    }
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (let key of keys1) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }

    return true;
  }

  // update qty cart handler
  function updateQtyHandler(cartId, updatedQty) {
    return new Promise((resolve, reject) => {
      setUserCart((prevUserCart) => {
        const updatedCart = prevUserCart.map((cart, idx) => {
          if (idx === cartId) {
            return { ...cart, qty: updatedQty };
          }
          return cart;
        });
        resolve(updatedCart); // Resolve with the updated cart
        return updatedCart;
      });
    });
  }

  // update click and collect cart handler
  function updateClickAndCollectHandler(cartId, store) {
    return new Promise((resolve, reject) => {
      setUserCart((prevUserCart) => {
        const updatedCart = prevUserCart.map((cart, idx) => {
          if (idx === cartId) {
            return { ...cart, collect_in_store: store };
          }
          return cart;
        });
        resolve(updatedCart); // Resolve with the updated cart
        return updatedCart;
      });
    });
  }

  // add qty cart handler
  function addQtyHandler(cartId) {
    return new Promise((resolve, reject) => {
      setUserCart((prevUserCart) => {
        const updatedCart = prevUserCart.map((cart, idx) => {
          if (idx === cartId) {
            return { ...cart, qty: cart.qty + 1 };
          }
          return cart;
        });
        resolve(updatedCart); // Resolve with the updated cart
        return updatedCart;
      });
    });
  }

  // remove qty cart handler
  function removeQtyHandler(cartId) {
    return new Promise((resolve, reject) => {
      setUserCart((prevUserCart) => {
        const updatedCart = prevUserCart.map((cart, idx) => {
          if (idx === cartId) {
            return { ...cart, qty: cart.qty - 1 };
          }
          return cart;
        });
        resolve(updatedCart); // Resolve with the updated cart
        return updatedCart;
      });
    });
  }

  //remove item from cart
  function removeFromBagHandler(cartId) {
    return new Promise((resolve, reject) => {
      setUserCart((prevUserCart) => {
        const updatedCart = [...prevUserCart];
        updatedCart.splice(cartId, 1);
        resolve(updatedCart); // Resolve with the updated cart
        return updatedCart;
      });
    });
  }
  //remove item from cart
  function removeAllFromBagHandler() {
    return new Promise((resolve, reject) => {
      setUserCart([]);
      resolve([]); // Resolve with an empty array, as the cart is now empty
    });
  }

  const context = {
    cart: userCart,
    totalCart: userCart.reduce(function (acc, obj) {
      return acc + obj.qty;
    }, 0),
    addToBag: addToBagHandler,
    uppdateToBag: updateQtyHandler,
    addQty: addQtyHandler,
    removeQty: removeQtyHandler,
    removeFromBag: removeFromBagHandler,
    removeAllFromBag: removeAllFromBagHandler,
    updateClickAndCollect: updateClickAndCollectHandler,
  };
  return (
    <CartContext.Provider value={context}>
      {props.children}
    </CartContext.Provider>
  );
};
export default CartContext;
