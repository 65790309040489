import { createContext, useState, useEffect } from "react";

const UserContext = createContext();

export const UserContextProvider = (props) => {
  const initialState = null;
  const [user, setUser] = useState(initialState);

  // for initial cart local storage
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user"));
    if (userData) {
      setUser(userData);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(user));
  }, [user]);

  // Function to set the user
  const setUserContext = (userData) => {
    setUser(userData);
  };

  const context = {
    user,
    setUserContext,
  };
  return (
    <UserContext.Provider value={context}>
      {props.children}
    </UserContext.Provider>
  );
};
export default UserContext;
