import { useState, useEffect, useCallback, createContext } from "react";
import { useRouter } from "next/router";

import Script from "next/script";
import Head from "next/head";
import "../styles/globals.css";
import dynamic from "next/dynamic";
import { ThemeProvider } from "@mui/material/styles";

import { CartContextProvider } from "../stores/cart-context";
import { VoucherContextProvider } from "../stores/voucher-context";
import { UserContextProvider } from "../stores/user-context";
import MuiTheme from "../utils/theme";

import { Breakpoint } from "../utils/media-query";
import { CustomerContextProvider } from "../stores/customer-context";

import { GoogleOAuthProvider } from "@react-oauth/google";
import * as ga from "../lib/ga";
import * as fbq from "../lib/fbpixel";
import * as ttq from "../lib/tiktokpixel";
import { sha256Hash } from "../utils/fbpixel.js/hash";

export const LoadingContext = createContext();

const LoadingText = () => <p>Loading...</p>;

const Layout = dynamic(() => import("../components/layout/layout"), {
  loading: LoadingText,
});

const Footer = dynamic(() => import("../components/footer/footer"), {
  loading: LoadingText,
});
const Loading = dynamic(() => import("../components/loading/loading"), {
  loading: LoadingText,
});
const PopupCampaign = dynamic(
  () => import("../components/layout/popup-campaign"),
  {
    loading: LoadingText,
  }
);
const PopupNewSubscriber = dynamic(
  () => import("../components/layout/popup-new-subscriber"),
  {
    loading: LoadingText,
  }
);
const PopupRegister = dynamic(
  () => import("../components/layout/popup-register"),
  {
    loading: LoadingText,
  }
);

export default function App({ Component, pageProps, userData }) {
  const router = useRouter();
  const [isDesktop, isMobile] = Breakpoint();
  const [loading, setLoading] = useState(false);
  const [isDark, setIsDark] = useState(false);

  const darkThemePage = ["/collection/villains"];

  useEffect(() => {
    const pathName = router.pathname;
    if (darkThemePage.includes(pathName)) {
      setIsDark(true);
    } else {
      setIsDark(false);
    }
  }, [router]);

  useEffect(() => {
    if (isDark) {
      localStorage.setItem("theme", JSON.stringify("dark"));
    } else {
      localStorage.setItem("theme", JSON.stringify("light"));
    }
  }, [isDark]);

  const handleStart = useCallback(() => setLoading(true), []);
  const handleComplete = useCallback((url) => {
    ga.pageview(url);
    fbq.pageview();
    ttq.pageview();

    setTimeout(() => setLoading(false), 300);
  }, []);

  useEffect(() => {
    // fbq.pageview();
    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleComplete);
    router.events.on("routeChangeError", handleComplete);

    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleComplete);
      router.events.off("routeChangeError", handleComplete);
    };
  }, [router, handleStart, handleComplete]);

  // useEffect(() => {
  //   if (loading) {
  //     // Start timer when component mounts
  //     const timer = setTimeout(() => {
  //       // Set loading to false after 3 seconds
  //       setLoading(false);
  //     }, 2000);

  //     // Clear timer on unmount
  //     return () => clearTimeout(timer);
  //   }
  // }, [loading]);
  // console.log(loading);
  // console.log(userData);
  useEffect(() => {
    localStorage.setItem("_fbu", JSON.stringify(userData));
  }, [userData]);

  return (
    <>
      <Script
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
      />

      <Script strategy="afterInteractive" id="gtm-script">
        {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
                    page_path: window.location.pathname,
                    });
                `}
      </Script>
      <Script id="google-tag-manager" strategy="afterInteractive">
        {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}');
      `}
      </Script>
      <Script
        type="module"
        src="https://ajax.googleapis.com/ajax/libs/model-viewer/3.0.1/model-viewer.min.js"
      ></Script>
      <Script
        id="fb-pixel"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '${fbq.FB_PIXEL_ID}',${JSON.stringify(userData)});
                fbq('track', 'PageView');
              `,
        }}
      />
      <Script
        id="tiktok-pixel"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
              !function (w, d, t) {
              w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++
              )ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
                ttq.load('${ttq.TIKTOK_PIXEL_ID}');
                ttq.page();
              }(window, document, 'ttq');
              `,
        }}
      />
      <Script
        id="microsoft-clarity"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "mf7w0wb6z9")`,
        }}
      />

      <Head>
        <title>Toko Perhiasan</title>

        <meta name="viewport" content="width-device-width, initial-scale=1" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <meta name="description" content="Perhiasan Emas Online Terpercaya" />
        <link rel="icon" href="/Aurum Lab Icon.ico" />
      </Head>

      {loading && <Loading />}
      <ThemeProvider theme={MuiTheme}>
        <GoogleOAuthProvider clientId={process.env.GOOGLE_CLIENT_ID}>
          <LoadingContext.Provider value={[loading, setLoading]}>
            <CartContextProvider>
              <VoucherContextProvider>
                <CustomerContextProvider>
                  <UserContextProvider>
                    <Layout isDesktop={isDesktop} dark={isDark}>
                      {/* <PopupCampaign /> */}
                      {/* <PopupNewSubscriber /> */}
                      <PopupRegister />
                      <Component {...pageProps} />
                      <Footer
                        isMobile={isMobile}
                        isDesktop={isDesktop}
                        dark={isDark}
                      />
                    </Layout>
                  </UserContextProvider>
                </CustomerContextProvider>
              </VoucherContextProvider>
            </CartContextProvider>
          </LoadingContext.Provider>
        </GoogleOAuthProvider>
      </ThemeProvider>
    </>
  );
}

App.getInitialProps = async ({ ctx }) => {
  try {
    const jwtToken = ctx.req.cookies.jwt || "";

    const api_url = process.env.API_URL;

    const response = await fetch(`${api_url}api/users/account`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      credentials: "include",
    });

    if (!response.ok) {
      // Handle error if needed
      return { userData: null };
    }

    const res = await response.json();
    const resData = res.data.user;

    // Create an object to hold user data
    const userDataObject = {};

    // Helper function to add a property to userDataObject if the value exists
    const addPropertyIfExist = (key, value) => {
      if (value) {
        userDataObject[key] = sha256Hash(value);
      }
    };
    const addPropertyIfExistNoHash = (key, value) => {
      if (value) {
        userDataObject[key] = value;
      }
    };

    const ipAddress = getClientIpAddress(ctx.req);
    const userAgent = getClientUserAgent(ctx.req);
    const clientFbp = getClientFbp(ctx.req);
    const clientFbc = getClientFbc(ctx.req);

    // Split full name into first name and last name
    const nameParts = resData.name.split(" ");
    const firstName = nameParts[0];
    const lastName = nameParts.length > 1 ? nameParts.slice(1).join(" ") : null;
    // Add properties to userDataObject based on the existence of data
    addPropertyIfExist("em", resData.email);
    addPropertyIfExist("ph", resData.phone);
    addPropertyIfExist("fn", firstName);
    addPropertyIfExist("ln", lastName);
    addPropertyIfExist("db", resData.birthday.replaceAll("-", ""));
    addPropertyIfExist("ge", resData.gender[0]);
    addPropertyIfExist("ct", resData.shipping?.kota || "");
    addPropertyIfExist("st", resData.shipping?.provinsi || "");
    addPropertyIfExist("zp", resData.shipping?.kodepos || "");
    addPropertyIfExist("country", "Indonesia"); // Hardcoded value
    addPropertyIfExist("external_id", resData.id);
    addPropertyIfExistNoHash("client_ip_address", ipAddress);
    addPropertyIfExistNoHash("client_user_agent", userAgent);
    addPropertyIfExistNoHash("fbp", clientFbp);
    addPropertyIfExistNoHash("fbc", clientFbc);

    return { userData: userDataObject };
  } catch (error) {
    return { userData: null };
  }
};

const getClientIpAddress = (req) => {
  const ipAddress = req.headers["x-real-ip"] || req.connection.remoteAddress;

  if (ipAddress) {
    return String(ipAddress);
  }

  const xForwardedFor = req.headers["x-forwarded-for"] ?? "";

  return xForwardedFor.split(",")[0];
};

const getClientUserAgent = (req) => String(req.headers["user-agent"] ?? "");

const getClientFbp = (req) => {
  const fbp = req.cookies._fbp || "";

  return fbp;
};

const getClientFbc = (req) => {
  if (req.headers.referer) {
    const url = new URL(req.headers.referer);
    if (url.searchParams.has("fbclid")) {
      return url.searchParams.get("fbclid") ?? "";
    }
  }

  const fbc = req.cookies._fbc || "";

  return fbc;
};
