import { useMediaQuery } from "react-responsive";
import { useComponentHydrated } from "react-hydration-provider";

export const Breakpoint = () => {
  const hydrated = useComponentHydrated();
  const isDesktop = useMediaQuery({ query: "(min-width: 1024px)" }, hydrated ? undefined : { deviceWidth: 1024 });
  const isTablet = useMediaQuery({ query: "(max-width: 1023px)" }, hydrated ? undefined : { deviceWidth: 1023 });
  const isMobile = useMediaQuery({ query: "(max-width: 650px)" }, hydrated ? undefined : { deviceWidth: 650 });
  return [isDesktop, isTablet, isMobile];
};
