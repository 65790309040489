export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FB_PIXEL_ID;

export const pageview = () => {
  window.fbq("track", "PageView");
};

export const event = (name, options = {}) => {
  window.fbq("track", name, options);
};
export const customevent = (name, options = {}) => {
  window.fbq("trackCustom", name, options);
};
export const eventId = () => {
  // Generate a random number between 1000 and 9999
  const randomPart = Math.floor(Math.random() * 9000) + 1000;

  // Get the current timestamp in seconds
  const timestampPart = Math.floor(Date.now() / 1000);

  // Combine the timestamp and random number to create a unique ID
  const uniqueId = `${timestampPart}${randomPart}`;

  return uniqueId;
};
