import { createContext, useState, useEffect } from "react";

const customerInitial = {
  customer: {
    billing_customer: {
      name: "",
      phone: "",
      email: "",
    },
    shipping_customer: {
      name: "",
      phone: "",
      address: "",
      kelurahan: "",
      kecamatan: "",
      kota: "",
      provinsi: "",
      kodepos: "",
      negara: "",
    },
  },
  addCustomerBillingName: () => {},
  addCustomerBillingPhone: () => {},
  addCustomerBillingEmail: () => {},
  addCustomerShipping: () => {},
};
const CustomerContext = createContext(customerInitial);

export const CustomerContextProvider = (props) => {
  const initialState = customerInitial;
  const [customer, setCustomer] = useState(initialState);

  // for initial customer local storage
  useEffect(() => {
    const customerData = JSON.parse(localStorage.getItem("customer"));
    if (customerData) {
      setCustomer(customerData);
    }
  }, []);

  useEffect(() => {
    if (customer !== initialState) {
      localStorage.setItem("customer", JSON.stringify(customer));
    }
  }, [customer]);

  function addCustomerBillingNameHandler(name) {
    setCustomer((cust) => {
      return {
        ...cust,
        customer: {
          ...cust.customer,
          billing_customer: { ...cust.customer.billing_customer, name },
        },
      };
    });
  }
  function addCustomerBillingPhoneHandler(phone) {
    setCustomer((cust) => {
      return {
        ...cust,
        customer: {
          ...cust.customer,
          billing_customer: { ...cust.customer.billing_customer, phone },
        },
      };
    });
  }
  function addCustomerBillingEmailHandler(email) {
    setCustomer((cust) => {
      return {
        ...cust,
        customer: {
          ...cust.customer,
          billing_customer: { ...cust.customer.billing_customer, email },
        },
      };
    });
  }
  function addCustomerShippingHandler(shippingData) {
    setCustomer((cust) => {
      return {
        ...cust,
        customer: {
          ...cust.customer,
          shipping_customer: {
            ...cust.customer.shipping_customer,
            ...shippingData,
          },
        },
      };
    });
  }
  const context = {
    customer: customer,
    addCustomerBillingName: addCustomerBillingNameHandler,
    addCustomerBillingPhone: addCustomerBillingPhoneHandler,
    addCustomerBillingEmail: addCustomerBillingEmailHandler,
    addCustomerShipping: addCustomerShippingHandler,
  };
  return (
    <CustomerContext.Provider value={context}>
      {props.children}
    </CustomerContext.Provider>
  );
};
export default CustomerContext;
